import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
const api = buildService('/companies/{id}/project_cross_claim_group_groups', {
  only: ['all', 'delete']
});
const useFetchCompanyProjectGroups = (companyId, setLoading) => {
  const [companyProjects, setCompanyProjects, fetchProjects] = serviceMethods.useFetch({
    callback: useCallback(async queryParams => await api.all(companyId, queryParams), [companyId]),
    setLoading
  });
  return [companyProjects, setCompanyProjects, fetchProjects];
};
const useDelete = _ref => {
  let {
    companyId,
    claimGroupId,
    projectCrossClaimGroupGroupId,
    setLoading
  } = _ref;
  const {
    t
  } = useTranslation();
  const user = useSelector(state => state.auth.user);
  const deleteModel = serviceMethods.useDelete({
    callback: useCallback(async () => {
      if (window.confirm(t('confirm_deleting_this_project_will_delete_it_from_all_missions'))) {
        const promise = await api.delete(companyId, projectCrossClaimGroupGroupId);
        window.location = "".concat(process.env.PUBLIC_URL, "/").concat(user.account_type, "/claim_groups/").concat(claimGroupId, "/project_overview");
        return promise;
      }
    }, [claimGroupId, companyId, projectCrossClaimGroupGroupId, t, user.account_type]),
    setLoading
  });
  return deleteModel;
};
const companyProjectCrossClaimGroupGroupsService = {
  base: api.base,
  useFetchCompanyProjectGroups,
  useDelete
};
export default companyProjectCrossClaimGroupGroupsService;